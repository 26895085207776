import {
  styled,
  useQuery,
  Text,
  Image,
  AssetsProvider,
  Link,
  notDesktop,
  css,
} from '@obvio/app'
import { Stack } from '@obvio/ui'
import { AnimatePresence, motion } from 'framer-motion'

import { QUERIES } from '@/constants/queries'

import type { ImageAsset } from '@obvio/app'
import type { AllowNullishForOptional } from '@obvio/utils'
import type { Variants } from 'framer-motion'
import type { ReactElement } from 'react'

const ArtistLink = motion(styled(Link)`
  all: unset;
  display: block;
  position: relative;
  > p {
    margin-bottom: 0;
    transition: transform 0.2s ease-in-out;
  }
  :hover {
    > p {
      transform: skew(-15deg);
    }
  }
`)

const ArtistsStack = motion(styled(Stack)`
  align-items: center;
  @media ${notDesktop} {
    > * {
      ${Stack.variables.spacing}: ${(theme) => theme.spacing.small}
    }
  }
`)

type ArtistRecordProps = AllowNullishForOptional<{
  name: string
  id: string
  image?: ImageAsset[]
  category?: string
  slug: string
  odd: boolean
}>

const variants = {
  hover: {
    opacity: 1,
    y: '-50%',
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: '-30%',
    transition: {
      duration: 0.3,
    },
  },
}

const ImageWrapper = motion(styled.div<{ $odd: boolean }>`
  display: block;
  position: absolute;
  min-height: 300px;
  max-height: 500px;
  min-width: 300px;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
  ${(_, { $odd }) =>
    $odd
      ? css`
          right: calc(100% + 1rem);
        `
      : css`
          left: calc(100% + 1rem);
        `}
  @media ${notDesktop} {
    display: none;
  }
`)

function ArtistRecord({ name, image, id, slug, odd }: ArtistRecordProps) {
  const img = image?.[0]
  return (
    <AssetsProvider path={`/merchant/${id}`}>
      <AnimatePresence>
        <ArtistLink
          whileHover="hover"
          initial="hidden"
          href={`/artist/${slug}`}
        >
          <Text tag="p" as="h4">
            {name}
          </Text>
          {img ? (
            <ImageWrapper variants={variants} tabIndex={-1} $odd={odd}>
              <Image img={img} aspectRatio />
            </ImageWrapper>
          ) : null}
        </ArtistLink>
      </AnimatePresence>
    </AssetsProvider>
  )
}

const variant: Variants = {
  show: {
    transition: {
      staggerChildren: 0.35,
      delayChildren: 0.35,
      delay: 0.25,
    },
  },
}

const itemA: Variants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.65, bounce: 0 } },
}

export function ArtistsListMain(): ReactElement {
  const { data } = useQuery('getMerchants', {
    select: QUERIES['artist-index'].getMerchants?.select ?? {},
  })

  return (
    <ArtistsStack
      kind="vertical"
      spacing="large"
      variants={variant}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      <Text as="sub-h4">ARTIST`S INDEX</Text>
      {
        data?.map((artist, i) => (
          <motion.div key={artist.id} variants={itemA}>
            <ArtistRecord {...artist} odd={i % 2 === 0} />
          </motion.div>
        )) as unknown as ReactElement
      }
    </ArtistsStack>
  )
}
