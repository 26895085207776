// import type { PrefetchQueryMap } from '@obvio/server'

import { parseResourceLink } from '@obvio/app'
import { BLOG_CAROUSEL_SELECT, BLOG_GRID_SELECT } from '@obvio/template'

import { ART_GRID_SELECT } from '@/components/ArtGrid'
import { ARTIST_OF_THE_DAY_SELECT } from '@/components/ArtistOfTheDay'

import type { PrefetchQueryMap } from '@obvio/server/pages/prefetchQueries'

// satisfies PrefetchQueryMap
export const QUERIES: PrefetchQueryMap<
  | 'artist-index'
  | 'blog-carousel'
  | 'blog-grid'
  | 'art-grid'
  | 'artist-of-the-day'
> = {
  'artist-index': {
    getMerchants: {
      select: {
        name: true,
        image: true,
        category: true,
        slug: true,
      },
    },
  },
  'blog-carousel': {
    getArticles: { select: BLOG_CAROUSEL_SELECT },
  },
  'art-grid': {
    getProducts: {
      args: ({ Art: art }: { Art: { id: string }[] }) => ({
        ids: art.map((v: { id: string }) => parseResourceLink(v.id).id),
        take: 14,
      }),
      select: ART_GRID_SELECT,
    },
  },
  'blog-grid': {
    getArticles: {
      select: BLOG_GRID_SELECT,
      args: () => ({ category: undefined }),
    },
    getArticleCategories: {
      select: {
        slug: true,
        title: true,
      },
    },
  },
  'artist-of-the-day': {
    getProducts: {
      args: (data) => ({
        byMerchant: [parseResourceLink(data.artist[0].id).id],
        take: 3,
      }),
      select: ARTIST_OF_THE_DAY_SELECT,
    },
  },
} as const
